<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-modal
    id="modal-xl"
    ok-title="Save"
    centered
    size="xl"
    :title="$t('Add product to list combo')"
    no-close-on-backdrop
    no-close-on-esc
    @ok="handleUpdate"
    @cancel="handleUpdate"
    @close="handleUpdate"
  >
    <b-row>
      <b-col lg="3">
        <ProductCategorySort
          :list="catItems"
          @handlerChangeCategory="handlerChangeCategory"
        />
      </b-col>
      <b-col lg="9">
        <b-row>
          <b-col lg="6">
            <div class="d-flex">
              <b-input-group class="input-group-merge w-100 round">
                <!-- <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-prepend> -->
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
              </b-input-group>
              <b-button class="ml-2 text-nowrap" @click="handlerSearch">
                {{ $t('Search') }}
              </b-button>
              <b-button class="ml-2 text-nowrap" @click="resetFilter">
                {{ $t('Reset') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
        <ProductList v-model="value" :list="items" />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable array-callback-return */
import {
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProductCategorySort from './ProductCategorySort.vue'
import ProductList from './ProductList.vue'

export default {
  components: {
    BInputGroup,
    BRow,
    BCol,
    ProductCategorySort,
    ProductList,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    // productList: {
    //   type: Object,
    //   default() {
    //     return {}
    //   },
    // },
  },
  data() {
    return {
      items: [],
      catItems: [],
      productListTemp: [],
      category_id: null,
      searchQuery: null,
    }
  },
  async mounted() {
    await this.loadList()
    this.loadListCategory()
    if (this.value.list_product_old.length > 0) await this.loadListProduct()
  },
  methods: {
    handlerChangeCategory(data) {
      this.category_id = data.item.id
      this.loadList(this.category_id)
    },
    handlerSearch() {
      this.loadList()
    },
    async resetFilter() {
      this.category_id = null
      this.searchQuery = null
      await this.loadList()
      this.loadListProduct()
    },
    handleUpdate() {
      const ids = this.value.list_product.map(o => o.id_number)
      const filtered = this.value.list_product.filter(({ id_number }, index) => !ids.includes(id_number, index + 1))
      this.value.list_product = filtered
    },
    async loadListProduct() {
      try {
        const listProductId = []
        this.value.list_product_old.map(x => listProductId.push(x.id))
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}&list_product_id=${JSON.stringify(listProductId)}&limit=100&sort[]=id,desc`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            // this.items = []
            // this.productListTemp = []
            res.data.data.items.map((val, idx) => {
              const item = {
                ...val,
                idx,
                sold: 0,
                discount_price: 0,
                after_discount_price: 0,
                is_combo_active: this.getIsActiveProdCombo(val),
              }
              const find = this.items.find(x => x.id_number === val.id_number)
              if (!find) {
                this.items.unshift(item)
                this.productListTemp.unshift(item)
              }
              if (item.combo_id === this.value.id_promotion) {
                this.value.list_product.push(item)
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList(categoryID = null) {
      try {
        let params = ''
        // if (this.value.date_from_to !== null) {
        //   if (this.value.date_from_to.split(' to ').length > 1) {
        //     const dateFromTo = this.value.date_from_to.split(' to ')
        //     params = `&date_from=${dateFromTo[0]}&date_to=${dateFromTo[1]}`
        //   }
        // }
        if (categoryID) {
          params += `&category_id=${categoryID}`
        }
        if (this.searchQuery) {
          params += `&name=${this.searchQuery}`
        }
        const listProductId = []
        this.value.list_product_old.map(x => listProductId.push(x.id))
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}${params}&is_not_combo=1&limit=40&sort[]=id,desc&combo_find=${this.value.id_promotion}&list_not_product_id=${JSON.stringify(listProductId)}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = []
            this.productListTemp = []
            res.data.data.items.map((val, idx) => {
              const item = {
                ...val,
                idx,
                sold: 0,
                discount_price: 0,
                after_discount_price: 0,
                is_combo_active: true,
              }
              this.items.push(item)
              this.productListTemp.push(item)
              // if (item.combo_id === this.value.id_promotion) {
              //   this.value.list_product.push(item)
              // }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    getIsActiveProdCombo(data) {
      return this.value.json_product.find(x => x.product_id === data.id_number).is_active
    },
    async loadListCategory() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.catItems = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
